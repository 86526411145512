@layer utilities {
  .badge {
    @apply w-fit text-sm font-bold rounded-3xl h-6 whitespace-nowrap px-2 gap-1 elevation flex items-center justify-center;

    &--primary {
      @apply badge bg-primary-8 text-primary-100;
    }

    &--blue {
      @apply badge bg-status-info-light text-status-info;
    }
    &--gray {
      @apply badge bg-status-neutral-light text-neutral-100;
    }
    &--green {
      @apply badge bg-status-success-light text-status-success;
    }
    &--purple {
      @apply badge bg-status-excite-light text-status-excite;
    }
    &--red {
      @apply badge bg-status-critical-light text-status-critical;
    }
    &--white {
      @apply badge bg-white text-neutral-80;
    }
    &--yellow {
      @apply badge bg-status-caution-light text-status-caution;
    }
    &--slate {
      @apply bg-neutral-100 text-white;
    }

    &--slate.removable {
      .removable-icon {
        @apply text-white;
      }
    }

    &--sm {
      @apply gap-1 min-w-fit h-4 px-1 py-1 text-sm;
      box-shadow: none;
    }

    &.removable {
      @apply gap-0 p-0 cursor-pointer;

      .removable-text {
        @apply ml-2;
      }

      .removable-icon {
        @apply mr-2 ml-2 text-neutral-64;
      }
    }

    &.hover:hover {
      @apply elevation--secondary no-underline;
    }

    .icon-opacity {
      @apply opacity-[48];
    }

    a {
      color: inherit;
    }
  }

  .badge--link {
    @apply w-fit rounded-3xl h-6 whitespace-nowrap px-2 gap-1 flex items-center justify-center bg-primary-3 text-primary-100 text-sm;
    box-shadow: 0px 0px 0px 1px theme("colors.primary.10") inset;

    &.hover:hover {
      @apply text-primary-100 elevation--secondary no-underline;
    }
  }
}
