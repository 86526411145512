@layer components {
  .react-tooltip {
    &-content {
      @apply bg-neutral-100 z-20 rounded-lg p-3 text-white max-w-[224px] elevation--overlay;

      &--sm {
        @apply react-tooltip-content px-3 py-2 text-sm;
      }

      &--base {
        @apply react-tooltip-content px-3 py-2 text-base;
      }

      &-error {
        @apply bg-white text-status-critical;

        .svg-inline--fa {
          @apply mr-1.5 mt-1;
        }
      }
    }

    &__container {
      // This must be greater than the standard z-index so it overlays properly.
      @apply z-[12];
    }
  }
}
